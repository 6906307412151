import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { WindowEventService } from '@sharedServices/window-event.service';
import { Subscription } from 'rxjs';

import { CustomLinkAttributes } from '../quill-formats/custom-link.bloat';
import { IPath } from '../../../../course-editor/widgets/recommendation-button-widget/path-selector/path-selector.component';
import { PageControllerService, WidgetControllerService } from '@apiServices';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ICourseEditorData } from '../../../../course-editor/services/editor-data.resolver';
import { PageResponse } from '@apiModels';

@Component({
    selector: 'app-quill-link-editor',
    templateUrl: './quill-link-editor.component.html',
    styleUrls: ['./quill-link-editor.component.scss'],
})
export class QuillLinkEditorComponent implements OnInit, OnDestroy {
    @Input() link: CustomLinkAttributes;
    @Output() editDone = new EventEmitter<{ action: 'DELETE' | 'CANCEL' | 'DONE'; link: CustomLinkAttributes }>();
    form: FormGroup<{ link: FormControl<string>; target: FormControl<string> }>;
    url: string;
    hasStartValue = false;
    subscription = new Subscription();
    mousedownTarget;

    targetOptions: IChekboxRadioFormModel[] = [
        { id: 'option-open-blank', label: 'QUILL.LINK-EDIT-LOCATION-BLANK', value: '_blank' },
        { id: 'option-open-local', label: 'QUILL.LINK-EDIT-LOCATION-SELF', value: '_self' },
    ];
    private courseId: number;
    pages: Array<PageResponse>;
    showPageSelect: boolean = false;

    constructor(
        private windowEventService: WindowEventService,
        private pageSrv: PageControllerService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe((data: { editorData: ICourseEditorData }) => {
            this.courseId = data.editorData.courseData.id;
        });
        this.url = this.link?.value ?? '';
        this.form = new FormGroup({
            link: new UntypedFormControl(this.link?.value ?? '', Validators.required),
            target: new UntypedFormControl(this.link?.target ?? '_blank'),
        });
        if (this.url) {
            this.hasStartValue = true;
        }

        this.subscription.add(
            this.windowEventService.mousedownEvent().subscribe((event: Event) => {
                this.mousedownTarget = event.target;
            })
        );

        this.subscription.add(
            this.windowEventService.clickEvent().subscribe((event: Event) => {
                if (
                    !this.mousedownTarget.closest('app-quill-link-editor') &&
                    !(event.target as HTMLElement).closest('app-quill-link-editor')
                ) {
                    this.cancel();
                }
            })
        );
        this.pageSrv.getAllPagesByCourseId({ courseId: this.courseId }).subscribe((p) => (this.pages = p));
    }

    getInternalLink(): number {
        return parseInt(this.form.value.link.replace('#/chapters/', ''));
    }

    addLink(): void {
        const value = this.form.value.link.trim();
        if (!value) {
            this.form.markAllAsTouched();
            return;
        }
        const target = this.form.value.target;
        const link = value ?? '';

        this.editDone.emit({ action: 'DONE', link: { value: link as string, target } });
    }

    deleteLink(): void {
        this.editDone.emit({ action: 'DELETE', link: null });
    }

    cancel(): void {
        this.editDone.emit({ action: 'CANCEL', link: null });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    pageSelected($event: number): void {
        if ($event === null) {
            this.showPageSelect = false;
            return;
        }
        this.form.patchValue({
            link: '#/chapters/' + $event.toString(),
        });
    }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '@shared/components/toast-message/toast-message.service';
import { KeycloakService } from 'keycloak-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private toastsSrv: ToastMessageService,
        protected readonly keycloak: KeycloakService,
        private router: Router
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            map((event: HttpEvent<unknown>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status >= 500) {
                    this.toastsSrv.error({ translatedMessage: `ERROR_CODES.UNKNOWN` });
                    return throwError(() => error);
                }
                const errorCode = error.error.hasOwnProperty('errorCode') ? error.error.errorCode : error.error.error;

                if (errorCode) {
                    this.toastsSrv.error({ translatedMessage: `ERROR_CODES.${errorCode}`, timeout: 5000 });
                }

                if (error.status === 401) {
                    setTimeout(() => {
                        void this.handleUnauthorizedError();
                    }, 1500);

                    return throwError(() => error);
                }
                if (errorCode === 404001) {
                    void this.router.navigate(['/page_deleted']);
                }

                return throwError(() => error);
            })
        );
    }

    async handleUnauthorizedError(): Promise<void> {
        await this.keycloak.login();
    }
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { WidgetCreateRequest } from '@apiModels';
import { TranslateService } from '@ngx-translate/core';
import { ChoiceAnserModel } from 'src/app/api-wrapper/models/widget-models/questions/choice-question/choice-anser.model';
import { MatchingAnswerModel } from 'src/app/api-wrapper/models/widget-models/questions/matching-question/matching-answer-model';
import { SortingAnswerModel } from 'src/app/api-wrapper/models/widget-models/questions/sorting-question/sorting-answer-model';

import { WidgetType } from '../configs/widget.config';

export interface ICreateWidgetFactory {
    widgetType: WidgetType;
    extraInfo?: any;
}

@Injectable()
export class CreateWidgetService {
    constructor(private translate: TranslateService) {}

    createWidgetFactory(widgetInfo: ICreateWidgetFactory, index: number, pageId: number): WidgetCreateRequest {
        const { widgetType, extraInfo } = widgetInfo;
        return {
            pageId,
            order: index + 2,
            data: {
                type: widgetType,
                size: 'MARGIN',
                ...(widgetType === 'QUESTION_WIDGET' && (extraInfo as string).includes('CHOICE_QUESTION')
                    ? {
                          question: {
                              questionType: extraInfo,
                              text: `<p>${this.translate.instant(`QUESTION-WIDGET.INSTRUCTION.${extraInfo}`)}</p>`,
                              answers: [
                                  new ChoiceAnserModel({
                                      correct: true,
                                  }).getDataForSave(),
                                  new ChoiceAnserModel({
                                      correct: false,
                                  }).getDataForSave(),
                              ],
                          },
                      }
                    : {}),
                ...(widgetType === 'QUESTION_WIDGET' && (extraInfo as string) === 'SORTING_QUESTION'
                    ? {
                          question: {
                              questionType: 'SORTING_QUESTION',
                              text: `<p>${this.translate.instant('QUESTION-WIDGET.INSTRUCTION.SORTING_QUESTION')}</p>`,
                              answers: [
                                  new SortingAnswerModel({
                                      order: 1,
                                  }).getDataForSave(),
                                  new SortingAnswerModel({
                                      order: 2,
                                  }).getDataForSave(),
                              ],
                          },
                      }
                    : {}),
                ...(widgetType === 'QUESTION_WIDGET' && (extraInfo as string) === 'MATCHING_QUESTION'
                    ? {
                          question: {
                              questionType: 'MATCHING_QUESTION',
                              text: `<p>${this.translate.instant('QUESTION-WIDGET.INSTRUCTION.MATCHING_QUESTION')}</p>`,
                              answers: [
                                  new MatchingAnswerModel({}).getDataForSave(),
                                  new MatchingAnswerModel({}).getDataForSave(),
                              ],
                          },
                      }
                    : {}),

                ...(widgetType === 'QUESTION_WIDGET' && (extraInfo as string) === 'GROUP_QUESTION'
                    ? {
                          question: {
                              questionType: 'GROUP_QUESTION',
                              text: `<p>${this.translate.instant('QUESTION-WIDGET.INSTRUCTION.GROUP_QUESTION')}</p>`,
                              groups: [
                                  { name: this.translate.instant('QUESTION-WIDGET.COMMON.GROUP-FIRST'), elements: [] },
                                  { name: this.translate.instant('QUESTION-WIDGET.COMMON.GROUP-SECOND'), elements: [] },
                              ],
                              nonGrouped: [],
                          },
                      }
                    : {}),

                ...(widgetType === 'QUESTION_WIDGET' && (extraInfo as string) === 'DROP_DOWN_CLOZE_QUESTION'
                    ? {
                          question: {
                              questionType: 'DROP_DOWN_CLOZE_QUESTION',
                              text: `<p>${this.translate.instant('QUESTION-WIDGET.INSTRUCTION.CLOSE_QUESTION')}</p>`,
                              gaps: [],
                          },
                      }
                    : {}),
                ...(widgetType === 'SIMPLE_VIDEO_WIDGET' || widgetType === 'VIDEO_TEXT_WIDGET'
                    ? {
                          controlsEnabled: true,
                      }
                    : {}),
                ...(widgetType === 'INFO_WIDGET'
                    ? {
                          color: '#009BEF',
                          pictogram: 'INFO',
                      }
                    : {}),
                ...(widgetType === 'CAROUSEL_WIDGET'
                    ? {
                          ratio: { x: 4, y: 3 },
                          images: [],
                      }
                    : {}),
                ...(widgetType === 'PAGE_RECOMMENDATION_WIDGET'
                    ? {
                          text: `<h3>${this.translate.instant('RECOMMENDATION-WIDGET.RECOMENDATION-HEADER')}</h3>`,
                          layout: extraInfo ?? 'TEXT_AND_CARD',
                      }
                    : {}),
                ...(widgetType === 'NAVIGATION_BLOCK_WIDGET'
                    ? {
                          columns: 2,
                          cards: [
                              {
                                  request: {
                                      isLesson: false,
                                      name: this.translate.instant('NAV-WIDGET.CHAPTER-TEXT'),
                                  },
                              },
                          ],
                      }
                    : {}),
                ...((widgetType as string).includes('_TEXT_WIDGET') ||
                (widgetType as string).includes('IMAGE_IMAGE_WIDGET')
                    ? {
                          dividerLocation: 'CENTER',
                      }
                    : {}),
                ...((widgetType as string).includes('ANCHOR_WIDGET')
                    ? {
                          structural: false,
                          visible: false,
                          size: 'NO_MARGIN',
                      }
                    : {}),
                ...((widgetType as string).includes('RECOMMENDATION_BUTTON_WIDGET')
                    ? {
                          align: 'LEFT',
                          text: this.translate.instant('RECOMMENDATION-BUTTON-WIDGET.UNNAMED'),
                      }
                    : {}),
            },
        };
    }
}

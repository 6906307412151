import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { IWidgetSupport } from '../../../models/widget-support-info.model';
import { RealTimeRandomQuestionForm } from '../../../../api-wrapper/models/widget-models/random-question-model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISelectOption } from '@shared/forms/select-input/select-input.component';
import { QuestionbankControllerService } from '@apiServices';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-random-question-real-time-widget',
    templateUrl: './random-question-real-time-widget.component.html',
    styleUrls: ['./random-question-real-time-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RandomQuestionRealTimeWidgetComponent),
            multi: true,
        },
    ],
})
export class RandomQuestionRealTimeWidgetComponent
    extends AbstractFormBaseComponent<RealTimeRandomQuestionForm, IWidgetSupport>
    implements OnInit
{
    @Input() editorLocation: 'real-time' | 'detail' = 'real-time';
    questionBankOptions$: Observable<ISelectOption[]>;

    constructor(private questionBankService: QuestionbankControllerService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.questionBankOptions$ = this.questionBankService.getAllQuestionBanks().pipe(
            map((questionBanks) => {
                return questionBanks
                    .filter(
                        (questionBank) =>
                            questionBank.questionCount > 0 || questionBank.id === this.form.get('questionbankId').value
                    )
                    .map((questionBank) => {
                        return { value: questionBank.id, displayName: questionBank.name };
                    });
            })
        );
    }

    createForm(): FormGroupTyped<RealTimeRandomQuestionForm> {
        return new FormGroupTyped<RealTimeRandomQuestionForm>({
            questionbankId: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    showSelector(): boolean {
        return this.editorLocation === 'detail' || !this.form.get('questionbankId');
    }
}

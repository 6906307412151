import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormBaseComponent } from '@shared/forms/abstract-form-base/abstract-form-base';
import { DetailRandomQuestionForm } from '../../../../api-wrapper/models/widget-models/random-question-model';
import { IWidgetSupport } from '../../../models/widget-support-info.model';
import { FormGroupTyped } from '@shared/models/formgroup-typed';
import { FormHelper } from '@sharedUtilities/form-helpers.utility';
import { collapseAnimation } from '@shared/animations/collapse.animations';
import { IChekboxRadioFormModel } from '@shared/models/checkbox-radio-form.model';
import { CourseEditorHelpers } from '../../../helpers/fulfillment.helper';
import { QuestionbankControllerService } from '@apiServices';
import { PageQuestionResponse, QuestionbankResponse } from '@apiModels';
import { TranslateWrapperService } from '@sharedServices/translate-wrapper.service';
import { forkJoin } from 'rxjs';
import { ISelectOption } from '@shared/forms/select-input/select-input.component';

@Component({
    selector: 'app-random-question-detail-widget',
    templateUrl: './random-question-detail-widget.component.html',
    styleUrls: ['./random-question-detail-widget.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RandomQuestionDetailWidgetComponent),
            multi: true,
        },
    ],
    animations: [collapseAnimation],
})
export class RandomQuestionDetailWidgetComponent
    extends AbstractFormBaseComponent<DetailRandomQuestionForm, IWidgetSupport>
    implements OnInit
{
    form: FormGroupTyped<DetailRandomQuestionForm>;
    questions: PageQuestionResponse;
    questionBank: QuestionbankResponse;
    questionEditEnabled = false;
    fulfillmentAvailableOptions: IChekboxRadioFormModel[] = [
        { id: 'fulfillment-available-option-true', label: 'FORM-CONTROLS.YES', value: true },
        { id: 'fulfillment-available-option-false', label: 'FORM-CONTROLS.NO', value: false },
    ];

    fulfillmentOptions: IChekboxRadioFormModel[] = [
        {
            id: 'fulfillment-option-show',
            label: 'QUESTION-WIDGET.COMMON.MEASUREMENT.DISPLAY',
            value: 'SHOW',
            group: 'LAX',
        },
        {
            id: 'fulfillment-option-started',
            label: 'QUESTION-WIDGET.COMMON.MEASUREMENT.ANSWERED-QUESTION',
            value: 'QUESTION_ANSWERED',
            group: 'MEDIUM',
        },
        {
            id: 'fulfillment-option-elapsed',
            label: 'QUESTION-WIDGET.COMMON.MEASUREMENT.CORECTLY-ANSWERED-QUESTION',
            value: 'QUESTION_CORRECTLY_ANSWERED',
            group: 'STRICT',
        },
    ];
    questionBankOptions: ISelectOption[];

    constructor(
        private questionBankService: QuestionbankControllerService,
        private translate: TranslateWrapperService,
        private change: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initDisabledFields();
        this.initQuestionData();
        (this.form as FormGroupTyped<DetailRandomQuestionForm>).valueChanges.subscribe((e) => {
            if (e.questionbankId != this.questionBank?.id) {
                this.questionEditEnabled = false;
                this.initQuestionData();
            }
        });
        this.initOptions();
    }

    private initOptions() {
        this.questionBankService.getAllQuestionBanks().subscribe((res) => {
            this.questionBankOptions = res
                .filter((bank) => bank.questionCount > 0 || bank.id === this.form.value.questionbankId)
                .map((bank) => ({ value: bank.id, displayName: bank.name }));
            this.change.detectChanges();
        });
    }

    private initQuestionData() {
        const id = this.form.value.questionbankId;
        // console.log(id);
        if (id) {
            this.getQuestionData(id).subscribe((d) => {
                this.questions = d.questions;
                this.questionBank = d.bank;
            });
        }
    }

    private getQuestionData(id: number) {
        return forkJoin({
            questions: this.questionBankService.getQuestionsByQuestionBank({ questionBankId: id }),
            bank: this.questionBankService.getQuestionBank({ questionBankId: id }),
        });
    }

    get attempts(): number {
        return this.form.get('attempts').value as number;
    }

    createForm(): FormGroupTyped<DetailRandomQuestionForm> {
        return new FormGroupTyped<DetailRandomQuestionForm>({
            questionbankId: FormHelper.controlFactoryWithCalculatedValue(null),
            attempts: FormHelper.controlFactoryWithCalculatedValue(null),
            enableAnswerExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
            enableCustomExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
            enableGeneralExplanation: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillmentAvailable: FormHelper.controlFactoryWithCalculatedValue(null),
            fulfillment: FormHelper.controlFactoryWithCalculatedValue(null),
        });
    }

    initDisabledFields(): void {
        const value = this.form.get('fulfillmentAvailable').value as boolean;
        if (!value) {
            this.form.get('fulfillment').disable({ emitEvent: false });
        }
        this.subscriptions.add(
            this.form.get('fulfillmentAvailable').valueChanges.subscribe((fulfillmentAvailable: boolean) => {
                CourseEditorHelpers.handleFulfillment(
                    fulfillmentAvailable,
                    this.form,
                    this.config.fulfillmentSettings.fulfillmentLevel,
                    this.fulfillmentOptions
                );
            })
        );
    }

    createQuestionBank(): void {
        this.questionBankService
            .createQuestionBank({
                body: {
                    name: this.translate.getInstantTranslate('QUESTION_BANK.INIT_TITLE'),
                },
            })
            .subscribe((bank) => {
                this.form.patchValue({ questionbankId: bank.id });
                this.questionBank = bank;
                this.questions = { content: [] };
                this.questionEditEnabled = true;
                this.change.detectChanges();
                this.initOptions();
            });
    }

    toggleEditing(): void {
        if (this.questionEditEnabled) {
            this.initQuestionData();
            this.initOptions();
        }
        this.questionEditEnabled = !this.questionEditEnabled;
    }
}

<form *ngIf="showSelector()" [formGroup]="form" data-e2e="random-question">
    <header *ngIf="editorLocation === 'real-time'" class="random-question-widget-header">
        <div class="random-question-widget-header__icon-wrapper">
            <span class="nitroicon-quiz question-type-icon"></span>
        </div>
        <div class="random-question-widget-header__text-wrapper">
            <h3>{{ 'RANDOM_QUESTION_WIDGET.SELECT_QUESTION_BANK' | translate }}</h3>
        </div>
    </header>
    <section class="detail-form">
        <label class="detail-form__label" for="questionbankId"
        >{{ 'RANDOM_QUESTION_WIDGET.QUESTION_BANK' | translate }}
        </label>
        <div class="question-bank-selector">
            <app-select-input
                id="questionbankId"
                [formControlName]="'questionbankId'"
                [startValue]="form.value.questionbankId"
                [options]="questionBankOptions$ | async"
            ></app-select-input>
        </div>
    </section>
</form>
<div *ngIf="!showSelector()">
    <app-question-readonly-display
    [questionbankId]="form.value.questionbankId">

    </app-question-readonly-display>
</div>

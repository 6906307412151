import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnchorWidgetData, PageResponse, WidgetResponse } from '@apiModels';
import { debounceTime, distinctUntilChanged, map, Observable, of, pluck, Subject, switchMap, takeUntil } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { MatRadioChange } from '@angular/material/radio';

export interface IPath {
    pageId: number;
    widgetId: number;
    name: string;
    page: PageResponse;
}
export interface IAnchorSelector {
    name: string;
    anchors: IPath[];
}

@Component({
    selector: 'app-inline-path-selector',
    templateUrl: './inline-path-selector.component.html',
    styleUrls: ['./inline-path-selector.component.scss'],
})
export class InlinePathSelectorComponent {
    @Input() inputPages: PageResponse[];
    @Output() editDone = new EventEmitter<number>();
    @Input() selected: number;

    onPageSelected(event: MatRadioChange): void {
        this.selected = event.value as number;
        this.editDone.emit(this.selected);
    }
}

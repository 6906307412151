<form [formGroup]="form" class="random-question-detail-form">
    <div class="wrapper">
        <div class="left">
                <div class="buttons">
                    <section >
                        <label class="detail-form__label" for="questionbankId"
                        >{{ 'RANDOM_QUESTION_WIDGET.QUESTION_BANK' | translate }}
                        </label>
                        <div class="question-bank-selector">
                            <app-select-input
                                *ngIf="questionBankOptions"
                                id="questionbankId"
                                [formControlName]="'questionbankId'"
                                [options]="questionBankOptions"
                            ></app-select-input>
                        </div>
                    </section>
                    <button
                        *ngIf="!questionEditEnabled"
                        (click)="createQuestionBank()"
                        class="editor-btn secondary"
                        type="button"
                    >
                        {{ 'RANDOM_QUESTION_WIDGET.CREATE_QUESTION_BANK'|translate }}
                    </button>
            </div>
            <button [disabled]="!questionBank || !questions"
                    (click)="toggleEditing()"
                    class="editor-btn secondary edit-button"
                    type="button"
            >
                {{ (questionEditEnabled ? 'RANDOM_QUESTION_WIDGET.FINISH_EDITING_QUESTION_BANK' : 'RANDOM_QUESTION_WIDGET.EDIT_QUESTION_BANK') |translate }}
            </button>
            <app-question-bank-editor
                *ngIf="questionBank && questions && questionEditEnabled"
                [questions]="questions"
                [questionBank]="questionBank"
            >
            </app-question-bank-editor>
        </div>
        <!--  Fullfillment -->
        <section class="fulfillment-details">
            <app-collapse-card [title]="'IMAGE-WIDGET.MEASUREMENT-CARD' | translate" [opened]="true">
                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'QUESTION-WIDGET.COMMON.MEASUREMENT.FULFILLMENT-AVAILABLE-TITLE' | translate"
                    [options]="fulfillmentAvailableOptions"
                    formControlName="fulfillmentAvailable"
                ></app-radio-group-control>

                <app-radio-group-control
                    class="detail-form__group"
                    [title]="'FORM-CONTROLS.FULFILLMENT-TITLE' | translate"
                    [options]="fulfillmentOptions"
                    formControlName="fulfillment"
                ></app-radio-group-control>
            </app-collapse-card>
        </section>
    </div>

</form>
